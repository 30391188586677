import styled from "@emotion/styled"

export const TextSize = styled.span`
  color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 2.5rem;
  font-weight: normal;
  line-height: 100%;
  position: relative;
  width: 2.5rem;
  vertical-align: middle;

  &:before,
  &.increase:after {
    border-bottom: 2px solid var(--color-light);
    content: '';
    display: block;
    height: 0;
    left: 15%;
    position: absolute;
    top: 50%;
    transform: translate(2px,-2px);
    width: 11px;
  }

  &.increase:after {
    transform: rotateZ(90deg) translate(-2px,-2px);
  }

  &.decrease {
    margin-left: 12px;
  }
`

export const Item = styled.div`
  padding: 0 1.6rem;
  float: right;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-light);

  &:last-child {
    border-right: none;
  }

  .icon {
    display: inline;
    height: 23px;
    position: relative;
    vertical-align: middle;
    margin: -0.3rem 0.3rem 0 0;

    &.phone {
      margin-right: 0.8rem;
      filter: brightness(0) invert(1);
    }
  }

  .hide-at-desktop{
    display:none;
      }
  @media only screen and (max-width: 912px) {
    font-size: 1.8rem;

    &:last-child {
      padding-right: 0;
    }
    .hide-at-desktop{
      display: inline;
      color: var(--color-primary-dark);
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-decoration: underline
        }

    .icon {
      &.phone {
        margin: -0.8rem 0.5rem 0 0;
        filter: brightness(0) saturate(100%) invert(23%) sepia(99%) saturate(1789%) hue-rotate(191deg) brightness(95%) contrast(104%);
      }
    }
  }
`

export const TopBanner = styled.div`
  background: var(--color-primary);
  flex: 0 0 100%;
  height: 47px;
  padding-top: 11px;
  margin-bottom: 20px;
  font-size: 18px;

  a {
    color: var(--color-light);
    font-family: var(--font-body);
    font-size: 18px;
    font-weight: 400;
    border-right: 1px solid;
    padding-right: 20px;
  }

  @media only screen and (max-width: 912px) {
    display: none;
  }
`

export const BottomBanner = styled.div`
  display: flex;
  flex: 0 0 100%;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  height: 47px;
  background: var(--color-primary);
  margin-top: 20px;
  color: var(--color-light);
  position: relative;
  z-index: -10;

  a.bottom-link {
    font-family: var(--font-body);
    font-size: 18px;
    font-weight: 600;
  }

  @media only screen and (min-width: 913px) {
    display: none;
  }
`

export const BottomSpacer = styled.div`
  height: 20px;
  backgroun-color: var(--color-light);
`

export const Wrapper = styled.div`
  flex-wrap: wrap;
  padding: 0 0 2.2rem 0;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.12);
  color: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  min-height: 120px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 400;

  a .icon { filter: none; }
  .icon { filter: none; }

  .header-right > div { border-color: var(--color-dark); }
  .header-right .text-size:before,
  .header-right .text-size.increase:after { border-color: var(--color-dark); }

  &.is-stuck {
    .header-right > div { border-color: var(--color-dark); }
    .header-right .text-size:before,
    .header-right .text-size.increase:after { border-color: var(--color-dark); }
  }

  &.takeover-active {
    color: var(--color-light);
    // important to override any blue stylings
    a { color: var(--color-light) !important; }
    a .icon { filter: brightness(0) invert(1); }
    .icon { filter: brightness(0) invert(1); }
    .site-logo { filter: brightness(0) invert(1); }
    .header-right > div { border-color: var(--color-light); }
    .header-right .text-size:before,
    .header-right .text-size.increase:after { border-color: var(--color-light); }
    .header-right > div > .shop-now { display: none; }
    .header-right > div > .find-agent { display: none; }
    .bottom-link { display: none; }
  }

  .bottom-link {
    text-decoration: underline;
    color: var(--color-light);
    margin: 0 auto;
    padding-top: 8px;
  }

  .after-nav {
    display: none;
  }

  @media only screen and (max-width: 1280px) {
    min-height: auto;
    position: fixed;
  }

  @media only screen and (max-width: 912px) {
    padding: 1rem 0 0;
    
    &.agent-page {
      // important to override any header padding changes on the agent page
      padding-bottom: 20px !important;
    }
  }

  @media only screen and (max-width: 600px) {
  padding: 0;
    &.takeover-active {
      position: fixed;
    }
    
    &.lp-header {
      margin-top: 81px;
    }

  .after-nav {
    display: block;
  }

    .hide-at-mobile {
      display: none;
      padding: 0;
      margin: 0;
    }
  }
`

export const Logo = styled.img`
  margin: 0 0 0 2rem;
  max-width: 193px;

  @media only screen and (max-width: 1024px) {
    max-width: 165px;
  }

  @media only screen and (max-width: 620px) {
    max-width: 117px;
    padding-top: 20px;
  }
`
