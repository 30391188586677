import React from 'react';
import { PageProps } from 'gatsby';
import FadeTransition from '../components/transitions/fade-transition';
import StaticHeaderSpacer from '@healthmarkets/components/src/components/static-header-spacer';
import BaseLayout from 'health-markets/src/components/Layout';
import Script from '../components/Script';
import './reset.css';
import './layout.scss';
import { getPageName } from 'health-markets/src/static/scripts/getPageName';


const Layout = ({ children, location}): React.ReactElement => {
    const pageName = getPageName(location.pathname);

    return (
    <>
        <div className="flex flex-col antialiased bg-white text-body font-body min-h[100vh]" id="main-content-area">
            <BaseLayout staticHeader pageName={pageName} plansPage={true}>
            <StaticHeaderSpacer />
            <FadeTransition shouldChange={location.pathname}>
                {children}
            </FadeTransition>
            </BaseLayout>
        </div>
        <Script
          id="invoca"
          body={`
          (function(i,n,v,o,c,a) { i.InvocaTagId = o; var s = n.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = ('https:' === n.location.protocol ? 'https://' : 'http://' ) + v; var fs = n.getElementsByTagName('script')[0]; fs.parentNode.insertBefore(s, fs); })(window, document, 'solutions.invocacdn.com/js/pnapi_integration-latest.min.js', '548/0332272152');
        `}
        />
    </>
)};

export default Layout;
