import React, { FC, useEffect } from "react"

// Styles
import { Wrapper, Logo, TopBanner, Item, TextSize, BottomBanner, BottomSpacer} from "./styles"

// Components
import HeaderRight from "./HeaderRight";

// Scripts
import {
  changeTextSize,
} from "../../static/scripts/global"

// Images
import headerLogo from "../../static/images/HMIA_logo2.png"
import PhoneIcon from "../../static/images/phone-icon.png"

export interface Props {
  headerData?: any
  staticHeader?: boolean,
  color?: String,
  pageBase?: String,
  lp?: boolean,
  agentPage?: boolean,
  pageName: string
}

const Header: FC<Props> = ({ headerData, staticHeader = false, color, pageBase, agentPage=false, lp=false, pageName }) => {
  const headerColor = (color) ? color : "dark";
  const staticClass = (staticHeader) ? "is-static" : "";

  const stickyHeader = () => {
    const header = document.getElementsByClassName('site-header')[0]
    const offset = header.offsetHeight / 2

    if (window.pageYOffset > offset) {
      header.classList.add('is-stuck')
    } else {
      header.classList.remove('is-stuck')
    }
  }

  useEffect(() => {
    stickyHeader();

    window.addEventListener("scroll", () => {
      stickyHeader();
    });
  }, [])


  return (
    <>
    <Wrapper className={`site-header ` + headerColor + ` ` + staticClass + ((lp) ? ` lp-header` : ``) + ((agentPage) ? ` agent-page` : ``)}>
      <TopBanner>
        <Item className="hide-at-mobile">
            <a className="telephone" href="tel:8008279990" data-gtm-id={`${pageName}-Header-CallBtn`}>
              <img className="phone icon" src={PhoneIcon} alt="telephone icon" width='23' height='23' />
            1-817-813-4562, TTY 711
            </a>
            <TextSize className="decrease text-size" onClick={changeTextSize}>-</TextSize> Text Size <TextSize className="increase text-size" onClick={changeTextSize}>+</TextSize>
          </Item>
      </TopBanner>

      {agentPage ? (
        <Logo className="site-logo" src={headerLogo} alt='Healthmarkets logo' width='192' height='68' />) :
      (<a href={process.env.GATSBY_SITE_BASE_URL} title="Go to Healthmarkets.com">
        <Logo className="site-logo" src={headerLogo} alt='Healthmarkets logo' width='192' height='68' />
      </a> )
      }  
        <HeaderRight pageBase={pageBase} headerData={headerData} agentPage={agentPage} pageName={pageName}/>
      {!agentPage && 
        <BottomBanner>
          <a className="bottom-link" href="/local-health-insurance-agent" data-gtm-id={`${pageName}-Header-AgentSearchBtn`}>Find a licensed insurance agent</a>
        </BottomBanner>
      }

    </Wrapper>
    </>
  )
}

export default Header;