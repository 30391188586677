import React from 'react'
import Cookie from "js-cookie";
import _omit from "lodash.omit";
import {parse, parseUrl, stringifyUrl} from "query-string";
import {sanitize} from "dompurify";
import { useLocation } from "@reach/router";

interface HMAnalyticsProps {
    pageName: string | null;
}

const HMAnalyticsComponent: React.FC<HMAnalyticsProps> = ({ pageName }) => {

    React.useEffect(() => {
        if (pageName == null) {
            return;
        }

        // don't run in development
        if (process.env.NODE_ENV === 'development') {
            return;
        }

        // Check if script is loaded, if not load it and wait for loaded event to run analytics
        if (!document.getElementById("hm-analytics-script")) {
            const analyticsScript = initAnalyticsScript();
            analyticsScript.addEventListener('load', runAnalytics);
        } else {
            runAnalytics();
        }
    }, [pageName]);

    const initLinks = () => {

        document.addEventListener('click', function (event) {

            // Check if the clicked element has the class name 'shop-link'
            const isShopLink = (event.target as Element)?.closest('.shop-link');
            const isShopLinkHref = (event.target as Element)?.closest('a[href^="https://shop.healthmarkets.com"], a[href^="http://shop.healthmarkets.com"]');

            // Check if the clicked element has the class name 'shop-link'
            if (isShopLink || isShopLinkHref) {

                // Prevent the default click behavior
              event.preventDefault();

              // Generate the new URL using the provided code snippet
              var newURL;
              if (isShopLinkHref) {
                newURL = window.MarketingParameterTracking.generateURLWithMarketingParams(isShopLinkHref.getAttribute('href'));
              } else {
                newURL = window.MarketingParameterTracking.generateURLWithMarketingParams('https://shop.healthmarkets.com/');
              }
          
              // Navigate to the new URL, in new tab if on the homepage.
              if (window.location.pathname === '/') {
                window.open(newURL, '_blank');
              } else {
                window.open(newURL, '_self');
              }
            }
        });

    };

    // saves marketing parameters to session and local storage
    const saveParameters = () => {
        window.MarketingParameterTracking.addParamsToLocalStorage();
    };

    // helper function to transform parsed query string into lower case keys
    const transformKeysToLowerCase = (obj: Record<string, any>): Record<string, any> => {
        const newObj: Record<string, any> = {};
        for (let key in obj) {
            newObj[key.toLowerCase()] = obj[key];
        }
        return newObj;
    };

    // checks for session token and returns it or returns null if no token
    const checkSessionToken = () => { 
        return getCookie('hmSessionToken');
    };

    // checks if this is a new session or if leadSourceName / salesChannel params have changed
    const isNewSession = (): boolean => {
        if (!checkSessionToken()) {
            return true;
        }
        let qs = parse(location.search)|| {};
        let modifiedQueryObj = transformKeysToLowerCase(qs);
        if (modifiedQueryObj["leadsourcename"] || null !== sessionStorage.getItem("leadSourceName")) {
            return true;
        }
        if (modifiedQueryObj["saleschannel"] || null !== sessionStorage.getItem("salesChannel")) {
            return true;
        }
        return false;
    };

    // checks if the site event params have changed
    const isNewSiteEvent = (): boolean => {
        let qs = parse(location.search)|| {};
        let modifiedQueryObj = transformKeysToLowerCase(qs);
        const marketingParams = window.MarketingParameterTracking.getParamsFromLocalStorage() || {};
        for (let queryItem in modifiedQueryObj) {
            if (marketingParams[queryItem] !== modifiedQueryObj[queryItem]) {
                return true;
            }
        }
        return false;
    };
    
    // sets new random session token
    const setSessionToken = () => {
        const sessionToken = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        setCookie('hmSessionToken', sanitize(JSON.stringify(sessionToken)));
    };

    // push session event data to adobe analytics
    const initSessionTrackingEvent = () => {
        const leadSourceName = window.MarketingParameterTracking.getMarketingParam("leadSourceName");
        const salesChannel = window.MarketingParameterTracking.getMarketingParam("salesChannel");
        window.adobeDataLayer = window.adobeDataLayer || [];
        const data = {
              'event': 'session',
              'session': {
                 leadSourceName,
                 salesChannel
              },
            }
        window.adobeDataLayer.push(data);
    };

    // push site event data to adobe analytics
    const initSiteTrackingEvent = () => {
        const marketingParams = window.MarketingParameterTracking.getParamsFromLocalStorage() || {};
        const utmCampaign = marketingParams.utm_campaign ?? null;
        const utmSource = marketingParams.utm_source ?? null;
        const utmTerm = marketingParams.utm_term ?? null;
        const utmContent = marketingParams.utm_content ?? null;
        const utmMedium = marketingParams.utm_medium ?? null;
        const marketingRefCode = marketingParams.MarketingRefCode ?? null;

        window.adobeDataLayer = window.adobeDataLayer || [];
        const data = {
            'event': 'site',
            'site': {
                utmCampaign,
                utmSource,
                utmTerm,
                utmContent,
                utmMedium,
                marketingRefCode
            },
            }
        window.adobeDataLayer.push(data);
    };

    // To Do: Remove the internal API call and replace with page name lookup context
    const initPageViewEvent = async () => {

        const previousPageName = localStorage.getItem('previousPageName') || '';

        // Get the currrent page name based on url path
        const currentPage = pageName || "";

        localStorage.setItem('previousPageName', currentPage);
    
        // Adobe Data Layer Script for PageView tracking
        window.adobeDataLayer = window.adobeDataLayer || [];
        const data = {
            'event': 'pageView',
            'pageInfo': {
                'pageName': currentPage,
                'pageURL': window.location.href,
                'previousPageName': previousPageName,
                'pageParams': window.location.search,
            },
        };
        window.adobeDataLayer.push(data);
    }

    const initAnalyticsScript = (): HTMLScriptElement => {
        const analyticsScript = document.createElement('script');
        analyticsScript.src = process.env.GATSBY_UHONE_ANALYTICS || "https://cdn.uhonedigital.com/scripts/analytics-configuration.min.js";
        analyticsScript.async = true;
        analyticsScript.id = 'hm-analytics-script';
        document.head.appendChild(analyticsScript);
        return analyticsScript;
    }

    const runAnalytics = () => {
        try{
            const newSession: boolean = isNewSession();
            const newSite: boolean = isNewSiteEvent();

            // save marketing parameters upon page load
            saveParameters();

            // checks if this is a new session event and if so triggers session and site tracking events
            if (newSession) {
                setSessionToken();
                initSessionTrackingEvent();
                initSiteTrackingEvent();
            } 
            // checks if this is a new site event and if so triggers site tracking event
            else if (newSite) {
                initSiteTrackingEvent();
            }

            // trigger page view event
            initPageViewEvent();

            // initiliaze shop links with params
            initLinks();}
        catch(err){
            console.log(err);
        }
    }

    const getCookie = (name: string) => {
        return Cookie.get(name);
    };

    const setCookie = (name: string, object: any, config = {}) => {
        return Cookie.set(name, object, config);
    };

    return null;
}

export default HMAnalyticsComponent;