
// Stop jumping to top of page when Algolia Infinte Scroll updates the page location.
export function shouldUpdateScroll({prevRouterProps, routerProps , getSavedScrollPosition}) {

    const prevLoc = prevRouterProps?.location?.pathname;
    const nextLoc = routerProps?.location?.pathname;

    if (prevLoc === nextLoc) {
        return false
    }

    return true
}