import { useContext } from 'react';
import { AgentContext } from '../contexts/AgentContext';

export const useAgentUrlOnShopLinks = ({ url }: { url: string }) => {

    const { agent } = useContext(AgentContext) || {};

    if (url !== 'https://shop.healthmarkets.com/' && url !== 'http://shop.healthmarkets.com/') {
        return url;
    }

    // If no agent code, just return the URL as is.
    if (!agent?.agent_code) {
        return url;
    }

    return `https://shop.healthmarkets.com/en/${agent.agent_code}`;
};
