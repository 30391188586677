import React from "react"
import { useAgentUrlOnShopLinks } from '../../hooks/useAgentUrlOnShopLinks';
import { useAgent } from "../../hooks/useAgent";

// Styles
import { Nav, Menu, MenuItem, Wrapper } from "./styles"

// Scripts
import { toggleSubnav } from "../../static/scripts/global"

// Components
import AfterNav from "./AfterNav"

const NavigationLink = ({ item, agent }: {item: any, agent: any}) => {
  let { url } = item;
  url = useAgentUrlOnShopLinks({ url });
  const label = item.label === "Shop Online" && (agent?.preferred_name || agent?.first_name) ? `Shop Online With ${agent?.preferred_name || agent.first_name}` : item.label;
  return (
    <a href={url} activeclassname='active' className={item.childItems.nodes.length !== 0 ? 'parent' : null}>
      {label}
    </a>
  );
};

const Navigation = ({ headerData, pageName }: { headerData: any, pageName: string }) => {
  const agent = useAgent();

  return (
    <Nav className="menu wrapper">
      <Wrapper>
      <Menu>
        {headerData?.menuItems && headerData?.menuItems?.nodes.map(item =>
          !item.parentId ? (
            <MenuItem key={item.id}
              className={item.childItems.nodes.length !== 0 ? `item has-submenu` : `item`}
              onClick={item.childItems.nodes.length !== 0 ? toggleSubnav : null}>
               <NavigationLink item={item} agent={agent}/>
                {item.childItems.nodes.length !== 0 ? (
                    <Menu className="submenu">
                        {item.childItems.nodes.map(child => (
                            <MenuItem key={child.id} className="item">
                                <a href={child.url} className="active">
                                    {child.label}
                                </a>
                            </MenuItem>
                        ))}
                    </Menu>
                ) : null}
            </MenuItem>
          ) : null
        )}
      </Menu>
      <AfterNav agent={agent} pageName={pageName}/>
      </Wrapper>
    </Nav>
  )
}

export default Navigation
