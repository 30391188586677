import React from "react";

// Styles
import {
  Wrapper,
  Item,
  TextSize,
  SearchButton
} from "./styles"

// Scripts
import {
  changeTextSize,
  toggleSearch
} from "../../../static/scripts/global";

// Images
import PhoneIcon from "../../../static/images/phone-icon.png"
import SearchIcon from "../../../static/images/search-icon.png"

// Components

const AfterNav = ({ agent, pageName }: { agent?: any, pageName: string }) => {

  return (
    <Wrapper className="show-at-mobile after-nav">
      <Item>
        <SearchButton className="search-button" onClick={toggleSearch}>
          <img className="icon" src={SearchIcon} alt="magnifying glass icon" width='19' height='19' />
        </SearchButton>
      </Item>
      <Item>
        <a data-gtm-id={`${pageName}-Nav-CallBtn`} href={agent ? `tel:${agent.phone_number}` : "tel:8008279990"}>
          <img className="icon" src={PhoneIcon} alt="telephone icon" width='19' height='19'/>
          {agent ? agent.phone_number : "(800) 827-9990, TTY 711"}
        </a>
      </Item>
      <Item>
        <a class="find-agent" href="/local-health-insurance-agent">
          Find a licensed insurance agent
        </a>
      </Item>
      <Item>
        Text Size <TextSize className="decrease text-size" onClick={changeTextSize}>-</TextSize> <TextSize className="increase text-size" onClick={changeTextSize}>+</TextSize>
      </Item>
    </Wrapper>
  )
}

export default AfterNav
