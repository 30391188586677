import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-right: 1rem;
  margin-top: 0.5rem;

  .shop-now {
    color: #009B3A;
    background-color: #FFFFFF;
    border: 2px solid #009B3A;
    font-family: var(--font-body);
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    width: 185px;
    height: 46px;
    border-radius: 4px;
    display: block;
    text-align: center;
    margin: auto;
    padding: 8px;

    &:hover {
      color: var(--color-accent);
      border-color: var(--color-accent);
    }
  }

  .find-agent{
    display: block;
    background-color: var(--color-accent-alt);
    text-align: center;
    color: var(--color-light);
    padding: 10px;
    width: 321px;
    height: 46px;
    font-family: var(--font-body);
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    border-radius: 4px;

    &:hover {
      background-color: var(--color-accent);
      border-color: var(--color-accent);
    }
  }

  @media only screen and (max-width: 912px) {
    .shop-now,
    .find-agent {
      display: none;
    }
  }

  @media only screen and (max-width: 620px) {
    margin-top: 0;
    padding-top: 20px;
  }
`

export const Item = styled.div`
  font-size: 2rem;
  font-weight: bold;
  padding: 0 0 0 1.6rem;

  .icon {
    display: inline;
    height: 23px;
    position: relative;
    vertical-align: middle;
    margin: -0.3rem 0.3rem 0 0;

    &.phone {
      margin-right: 0.8rem;
      filter: brightness(0) saturate(100%) invert(30%) sepia(7%) saturate(18%) hue-rotate(15deg) brightness(92%) contrast(90%);
    }
  }

  .hide-at-desktop {
    display: none;
  }

  @media only screen and (max-width: 912px) {
    font-size: 1.8rem;

    &:last-child {
      padding-right: 0;
    }

    .hide-at-desktop {
      display: inline;
      color: var(--color-primary-dark);
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-decoration: underline;
      margin-top: .8rem;
    }

    .icon {
      &.phone {
        margin: -0.8rem 0.5rem 0 0;
        filter: brightness(0) saturate(100%) invert(23%) sepia(99%) saturate(1789%) hue-rotate(191deg) brightness(95%) contrast(104%);
      }
    }
  }
`

export const SearchButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  height: 34px;
  margin-left: 1.3rem;
  padding: 0;
  width: 34px;
`

export const MenuButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  height: 34px;
  margin-left: 1.3rem;
  padding: 0;
  width: 34px;
  
  .light & > div {
    border-color: var(--color-light);
    &:before, &:after { border-color: var(--color-light); }
  }
  
  .dark & > div,
  .is-stuck & > div {
    border-color: var(--color-dark);
    &:before, &:after { border-color: var(--color-dark); }
  }

  &.nav-active > div {
    border-color: transparent;
    top: -10px;
    
    &:before, &:after {
      border-color: var(--color-light);
      left: 3px;
    }

    &:before { transform: rotate(45deg) translate(5px, 13px); }
    &:after  { transform: rotate(-45deg) translate(-1px, -8px); }
  }
`

export const Bars = styled.div`
  border-bottom: 2px solid var(--color-dark);
  height: 0px;
  position: relative;
  top: -3px;
  transition: all 0.22s linear;
  width: 28px;

  &:before,
  &:after {
    content: '';
    display: block;
    border-bottom: 2px solid var(--color-dark);
    height: 0px;
    position: relative;
    transition: all 0.22s linear;
    width: 28px;
  }

  &:before {
    top: -9px;
  }

  &:after {
    top: 7px;
  }
`
