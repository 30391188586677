import React from "react";

/*
	This component creates opacity transition based on the shouldChange prop changing.
 */

interface Props {
  shouldChange: string;
}

const FadeTransition: React.FC<Props> = ({ shouldChange, children }) => {
  const prevChildren = React.useRef<React.ReactNode>();
  const currentChildren = React.useRef<React.ReactNode>();
  const currentContainer = React.useRef<HTMLDivElement>();

  const [isAnimating, setIsAnimating] = React.useState<boolean>(false);

  const currentKey = React.useRef<string>(null);

  if (
      currentKey?.current !== null &&
      currentKey.current !== shouldChange &&
      currentChildren?.current &&
      currentChildren.current !== children
  ) {
    currentKey.current = shouldChange;
    prevChildren.current = currentChildren.current;
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 200);
  } else {
    currentChildren.current = children;
    currentKey.current = shouldChange;
  }

  return (
      <div className="grow flex w-full h-full relative">
        <div className="grow flex h-full flex-col w-full transition duration-300" ref={currentContainer} style={{opacity: isAnimating ? 0 : 1}}>
          {isAnimating ? prevChildren.current : children}
        </div>
      </div>
  );
};

export default FadeTransition;
