import React from 'react'
import { useLocation } from "@reach/router";

const Script = ({ body, id }: { body: string, id: string }) => {

    const { pathname } = useLocation()

    React.useEffect(() => {
        if (!document.querySelector(`script[data-id="${id}"]`)) {
            const scriptElm = document.createElement('script');
            scriptElm.setAttribute("data-id", id)
            const inlineCode = document.createTextNode(body);
            scriptElm.appendChild(inlineCode);
            document.body.appendChild(scriptElm)
        }
        if (window && window.Invoca && !window.location.href.includes("local-health-insurance-agent/")) {
            window.Invoca.PNAPI.run();
        }
        return () => {

        }
    }, [pathname])

    return null
}

export default Script