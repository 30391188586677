import React, {FC, ReactNode} from 'react';
import {Global} from "@emotion/react";
import {GlobalStyles, Wrapper} from "./styles";
import '../../static/css/typography.css';
import { useLocation } from '@reach/router';
// Components
import Header from "../Header";
import HMAnalyticsComponent from "../../static/scripts/analytics";
import { getPageName } from '../../static/scripts/getPageName';
import PlansHeader from "../PlansHeader";

export interface LayoutProps {
    headerData?: any
    pageClass?: String,
    staticHeader?: boolean,
    headerColor?: String,
    children: ReactNode,
    pageBase?: String,
    pageName?: string,
    plansPage?: boolean
}

const LayoutComponent: FC<LayoutProps> = ({ headerData, pageClass, staticHeader, headerColor, children, pageBase, pageName = null, plansPage = false }) => {
    const location = useLocation();
    const agentPage = location.host === 'localhost:8300' || location.pathname.includes('/local-health-insurance-agent');

    return (
        <Wrapper className={`healthmarkets22 ${pageClass ? pageClass : ""}`}>
            {/* <Script id="ga" src="https://cdne-uho-cdn-eastus-prod.azureedge.net/scripts/analytics-configuration.min.js" /> */}
            {plansPage ? (
                <>
                    <PlansHeader headerData={headerData} staticHeader={staticHeader} color={headerColor} agentPage={agentPage} lp={(pageClass === 'aca-insurance-plans')} pageName={pageName}/>
                </>
            )
            :(
                <>
                    <Global styles={GlobalStyles}/>
                    <Header headerData={headerData} staticHeader={staticHeader} color={headerColor} agentPage={agentPage} lp={(pageClass === 'aca-insurance-plans')} pageName={pageName}/>
                </>
            )
            }
            {children}
            <HMAnalyticsComponent pageName={pageName}/>
        </Wrapper>
    )
};

export default LayoutComponent;
